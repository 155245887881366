<template>
  <div class="full-width full-height bg-blue  text-lightblue p-md">
    <back url="/resource/index" class="m-b-sm" />

    <div class="f-hd flex items-center justify-between m-t-sm m-b-sm">
      <div class="flex items-center flex-one text-lightblue">
        <span class="flex-s m-r-xs">创建人：</span>
        <a-input
          placeholder="请输入创建人姓名"
          class="search-mx-wd-1 m-r-sm"
          v-model="cameraUser"
        />
        <span class="flex-s m-r-xs">摄像机组名称：</span>
        <a-input
          placeholder="请输入摄像机组名称"
          class="search-mx-wd-1 m-r-sm"
          v-model="cameraGroupName"
        />
        <a-button
          type="primary"
          class="m-r-sm btn-search"
          @click="searchHandle"
        >
          搜索
        </a-button>
        <a-button class="btn-reset" @click="resetHandle">
          重置
        </a-button>
      </div>
      <div class="flex items-center">
        <span class="span-line m-l-md m-r-md"></span>
        <a-button class="btn-export m-r-sm" @click="addGroupHandle">
          新增组
        </a-button>
        <a-dropdown>
          <a-button class="btn-export m-r-sm">
            批量处理
          </a-button>
          <a-menu slot="overlay" @click="systemHandle">
            <a-menu-item key="1">
              <a href="javascript:;">删除</a>
            </a-menu-item>
            <a-menu-item key="2">
              <a href="javascript:;">关联用户</a>
            </a-menu-item>
            <a-menu-item key="3">
              <a href="javascript:;">关联角色</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

        <a-button class="btn-export" @click="exportHandle" v-if="false">
          数据导出
        </a-button>
      </div>
    </div>
    <div class="table-content">
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :components="resibleTableHeader"
        :data-source="dataList"
        :pagination="paginationOpt"
        :rowKey="(record, index) => record.groupId"
        :expandedRowKeys="expandedRowKeys"
        :scroll="scroll"
        :loading="loading"
        bordered
      >
        >
        <div
          slot="operation"
          slot-scope="text, records"
          class="flex items-center"
        >
          <span class="flex full-width items-center  justify-center text-center f16">
            <a-tooltip placement="top">
              <template slot="title">
                <span>摄像机投屏</span>
              </template>
              <i
                class="icon-project m-r-sm btn"
                @click="projectHandle(records)"
              ></i>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>查看详情</span>
              </template>
              <a-icon type="copy" class="m-r-sm" @click="viewDetail(records)" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>修改</span>
              </template>
              <a-icon type="edit" class="m-r-sm" @click="editHandle(records)" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>删除</span>
              </template>
              <a-icon
                type="rest"
                class="m-r-sm"
                @click="delSingleHandle(records)"
              />
            </a-tooltip>
            <!-- <i
                class="icon-g icon-export pointer m-r-xs"
                @click="exportHandle(records)"
              ></i>
              <i class="icon-g icon-loop pointer" v-if="false"></i> -->
          </span>
          <span class="flex items-center" v-if="false">
            <span class="span-line m-l-md m-r-md"></span>
            <i class="icon-cameral pointer"></i>
          </span>
        </div>
        <!-- <a-switch
          title="启用/禁用"
          slot="selectOpe"
          slot-scope="text, records"
          :checked="text == 1 ? true : false"
          @change="changeStatus($event, records)"
        /> -->
      </a-table>
    </div>

    <new-group-dialog
      :visible="groupAddVisible"
      :edit="isEdit"
      :resource="true"
      :isChild="true"
      @refreshData="refreshDataHandle"
      @closeDialog="closeDialogHandle"
    />
    <user-modal
      :visible="userVisible"
      :groupIds="selectedRowKeys"
      :groupList="selectedRows"
      @close="userVisible = false"
      @confirm="confirmUserHandle"
    />
    <role-modal
      :visible="roleVisible"
      :groupIds="selectedRowKeys"
      :groupList="selectedRows"
      @close="roleVisible = false"
      @confirm="confirmRoleHandle"
    />
    <dialog-detail
      :visible="visibleDetail"
      :currentRecords="currentRecords"
      @close="visibleDetail = false"
    />
    <camera-group-setting
      ref="cameraGroupSetting"
      :visible="visibleGroupSetting"
      @close="closeGroupSettingHandle"
      @currentScreen="currentScreenHandle"
    />
  </div>
</template>

<script>
import Back from '@/components/Back';
import { CODE_OK } from '@/request/config_code';
import NewGroupDialog from '@/components/NewGroupDialog';
import RoleModal from './components/RoleModal';
import UserModal from './components/UserModal';
import DialogDetail from './components/DialogDetail';
import CameraGroupSetting from '@/components/CameraGroupSetting';
import ResizableTable from '@/components/mixins/resizeableTable';
import { getStorage, setStorage } from '@/tools';
import { mapMutations } from 'vuex';
export default {
  name: 'CameralGroup',
  mixins:[ResizableTable],
  data() {
    return {
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 20, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getCameraGroupLists();
          // this.searchCameraFrom(); //显示列表的接口名称
          // this.searchCameraFromTest();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.resetSelect();
          this._getCameraGroupLists();
          // this.searchCameraFrom();
          // this.searchCameraFromTest();
        },
      },
      /* data, */
      columns: [
        {
          title: '序号',
          dataIndex:'groupIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          title: '摄像机组名称',
          dataIndex: 'groupName',
          key: 'groupName',
          width: 180,
          ellipsis: true,
        },
        {
          title: '描述',
          dataIndex: 'remark',
          key: 'remark',
          width: 180,
          ellipsis: true,
        },
        {
          title: '摄像机数',
          dataIndex: 'deviceCount',
          key: 'deviceCount',
          width: 60,
          ellipsis: true,
        },
        {
          title: '关联用户数',
          dataIndex: 'userCount',
          key: 'userCount',
          width: 60,
          ellipsis: true,
        },
        {
          title: '创建人',
          dataIndex: 'createUser',
          key: 'createUser',
          width: 80,
        },
        {
          title: '创建时间',
          dataIndex: 'createDate',
          key: 'createDate',
          width: 120,
          ellipsis: true,
        },
        {
          title: '当前状态',
          dataIndex: 'status',
          key: 'status',
          // scopedSlots: { customRender: 'selectOpe' },
          customRender: (text) => {
            return text === 1 ? '启用' : '停用';
          },
          width: 60,
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 100,
          className:'column-center'
        },
      ],

      selectedRowKeys: [],
      selectedRows: [],
      cameraGroupName: '',
      cameraUser: '',
      groupAddVisible: false,
      isEdit: false,
      expandedRowKeys: [],
      dataList: [],
      userVisible: false,
      roleVisible: false,
      visibleDetail: false,
      currentRecords: {},
      visibleGroupSetting: false,
      scroll: { y: window.innerHeight - 335 ,x:'100%'},
      loading: false,
    };
  },
  components: {
    Back,
    NewGroupDialog,
    RoleModal,
    UserModal,
    DialogDetail,
    CameraGroupSetting,
  },
  computed: {},
  watch: {},
  mounted() {
    this._getCameraGroupLists();
  },
  methods: {
    ...mapMutations(['setCurrentCameraGroup', 'setGroupId']),
    _getCameraGroupLists() {
      let obj = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        groupName: this.cameraGroupName,
        createUser: this.cameraUser,
      };
      this.loading = true;
      this.$api.cameraGroup.getGroupList(obj).then((res) => {
        // console.error(res);
        if (res.code === CODE_OK) {
          this.loading = false;
          this.dataList = res.data;
          this.paginationOpt.total = res.total;
        }
      });
    },
    resetSelect() {
      this.selectedRowKeys = [];
    },
    searchHandle() {
      this._getCameraGroupLists();
      this.resetSelect();
    },
    resetHandle() {
      this.cameraGroupName = '';
      this.cameraUser = '';
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this.searchHandle();
    },
    refreshDataHandle() {
      this.resetHandle();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    changeStatus(value, records) {
      if (!this.hasPermission(1092111318)) {
        this.permissionWarning();
        return;
      }

      // console.error(value, records);
      let status = value ? 1 : 0;
      records.status = status;
      let paramObj = {
        groupId: records.groupId,
        params: {
          status,
        },
      };
      this.$api.cameraGroup.openStatus(paramObj).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success(`${value ? '启用' : '停用'}成功！`);
        }
      });
    },
    addGroupHandle() {
      if (!this.hasPermission(1092111310)) {
        this.permissionWarning();
        return;
      }

      this.groupAddVisible = true;
      this.isEdit = false;
    },
    systemHandle({ key }) {
      // console.log('haha', key, this.drawerDom);
      // this.drawerDom.showDrawer();
      switch (key) {
        case '1':
          {
            let params = {
              groupIds: this.selectedRowKeys,
            };
            this.delHandle(params, this.selectedRows);
          }
          break;
        case '2':
          this.relateUser();
          break;
        case '3':
          // this.$router.push({ path: '/ai-center' });
          this.relateRole();
          break;
      }
    },
    relateUser() {
      if (!this.hasPermission(1092111316)) {
        this.permissionWarning();
        return;
      }

      this.relateTip('userVisible');
    },
    relateRole() {
      if (!this.hasPermission(1092111317)) {
        this.permissionWarning();
        return;
      }

      this.relateTip('roleVisible');
    },
    relateTip(vis) {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择摄像机组');
        return;
      }
      this[vis] = true;
    },
    confirmUserHandle() {
      this.resetHandle();
    },
    confirmRoleHandle() {
      this.resetHandle();
    },
    delSingleHandle(records) {
      let params = {
        groupIds: [records.groupId],
      };
      // console.log(params);
      this.delHandle(params, records);
    },
    delHandle(params, records) {
      if (!this.hasPermission(1092111314)) {
        this.permissionWarning();
        return;
      }
      let description = '';
      if (params.length > 1) {
        let groupNames = records.map((item) => item.groupName).join(',');
        description = `批量删除摄像机组:${groupNames}`;
      } else {
        description = `删除摄像机组:${records.groupName}`;
      }
      let objParams = {
        data: params,
        instructionsDel: {
          module: '摄像机组管理',
          page: '',
          feature: `${params.length > 1 ? '批量' : ''}删除摄像机组`,
          description,
        },
      };
      this.$confirm({
        title: '提示',
        content: '你确定删除该摄像组吗?',
        onOk: () => {
          this.$api.cameraGroup.batchDel(objParams).then((data) => {
            if (data.code === CODE_OK) {
              this.$message.success('删除成功');
              this.searchHandle();
            }
          });
        },
        onCancel: () => {},
      });
    },
    editHandle(params) {
      if (!this.hasPermission(1092111311)) {
        this.permissionWarning();
        return;
      }

      this.$api.cameraGroup
        .getSelectedCamera({
          groupId: params.groupId,
        })
        .then((res) => {
          if (res.code === 200) {
            let newObj = JSON.parse(JSON.stringify(params));
            newObj.children = res.data;
            this.isEdit = true;
            this.groupAddVisible = true;
            this.setCurrentCameraGroup(newObj);
            this.setGroupId(newObj.id);
          }
        });
    },
    closeDialogHandle() {
      this.groupAddVisible = false;
      this.isEdit = false;
    },
    //查看详情
    viewDetail(records) {
      this.currentRecords = records;
      this.visibleDetail = true;
    },
    projectHandle(folder) {
      if (!this.hasPermission(1092111313)) {
        this.permissionWarning();
        return;
      }

      let cameraInfoStore = getStorage('groupCamera');
      if (cameraInfoStore && JSON.stringify(cameraInfoStore) != '{}') {
        this.$message.error('每组摄像机只能同时投屏一次！');
        return;
      }
      this.visibleGroupSetting = true;
      this.groupId = folder.groupId;
    },
    closeGroupSettingHandle() {
      this.visibleGroupSetting = false;
    },
    currentScreenHandle(curScree) {
      let currentScreen = curScree.mode;
      let tit = curScree.litTit;
      let interval = curScree.interval;
      // this.visibleGroupSetting = false;
      // console.log(currentScreen, tit, this.projectDataList);
      let { href } = this.$router.resolve({
        path: `/${currentScreen}-window${tit}`,
      });
      let paramsObj = {
        groupId: this.groupId,
        params: {
          currPage: 0,
          pageSize: 0,
        },
      };
      this.$refs['cameraGroupSetting'].setLoading(true);
      this.$api.cameraGroup.getCemeraListDetail(paramsObj).then((res) => {
        if (res.code === CODE_OK) {
          this.$refs['cameraGroupSetting'].setLoading(false);
          this.projectDataList = res.data;
          this.visibleGroupSetting = false;
          window.open(`${href}?isGroup=1`, '_blank');
          setStorage('groupCamera', {interval,arr:this.projectDataList});
        }
      });
    },
  },
};
</script>

<style scoped>
.icon-g {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.icon-edit {
  background-image: url('../../assets/resource/icon-edit.png');
}
.icon-del {
  background-image: url('../../assets/resource/icon-del.png');
}
.icon-auth {
  background-image: url('../../assets/resource/icon-auth.png');
}
.icon-loop {
  background-image: url('../../assets/resource/icon-loop.png');
}
.icon-export {
  background-image: url('../../assets/cameral_group/export-icon.png');
  width: 16px;
  height: 16px;
}
.icon-project {
  background: url('~@/assets/resource/icon-project.png') no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 13px;
}
.icon-cameral {
  width: 20px;
  height: 20px;
}
.flex-s {
  flex-shrink: 0;
}
</style>
