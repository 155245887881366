<template>
  <a-modal
    title="查看详情"
    :visible="visible"
    :maskClosable="false"
    width="80%"
    :footer="null"
    :centered="true"
    @cancel="handleCancel"
  >
    <div>
      <div class="select-info m-b-sm">
        <div class="flex items-center flex-wrap m-b-xs">
          <span class="flex-s m-r-xs">所含角色：</span>
          <div class="scroll scroll-ht">
            <span
              class="sp-item ellipsis m-r-xs"
              v-for="item in currentRole"
              :key="item.roleCode"
              >{{ item.roleName }}</span
            >
          </div>
        </div>
        <div class="flex items-center flex-wrap">
          <span class="flex-s m-r-xs">所含用户：</span>
          <div class="scroll scroll-ht">
            <span
              class="sp-item ellipsis m-r-xs"
              v-for="item in currentUser"
              :key="item.userId"
              >{{ item.loginName }}</span
            >
          </div>
        </div>
      </div>
      <title-com text="摄像机列表" />
      <div class="search flex justify-between m-t-xs m-b-xs">
        <div class="flex items-center text-lightblue flex-one">
          <span class="flex-s m-r-xs">管辖单位：</span>
          <a-cascader
            :options="organizationList"
            :display-render="displayRender"
            v-model="organizationId"
            class="search-mx-wd-1 m-r-sm"
            expand-trigger="hover"
            placeholder="管辖单位"
            @change="selectOrganization"
            change-on-select
          />

          <span class="flex-s m-r-xs">归属路线：</span>
          <a-select
            placeholder="请选择所属路线"
            v-model="roadId"
            class="search-mx-wd-1 m-r-sm"
            @change="selectRoad"
          >
            <a-select-option
              v-for="(item, index) in roadList"
              :key="index"
              :value="item.roadCode"
            >
              {{ item.roadCode + ' ' + item.roadName }}
            </a-select-option>
          </a-select>
          <span class="flex-s m-r-xs">摄像机名称：</span>
          <a-input
            placeholder="请输入摄像机名称"
            class="search-mx-wd-1 m-r-sm"
            v-model="cameraName"
          />
          <a-button
            type="primary"
            class="m-r-sm btn-search"
            @click="searchHandle"
          >
            搜索
          </a-button>
          <a-button class="btn-reset" @click="resetHandle">
            重置
          </a-button>
        </div>
        <div class="flex items-center">
          <span class="span-line m-l-md m-r-md"></span>
          <a-button class="btn-export" @click="exportHandle">
            数据导出
          </a-button>
        </div>
      </div>

      <a-table
        ref="tableCon"
        :components="resibleTableHeader"
        :columns="columns"
        :data-source="dataList"
        :pagination="paginationOpt"
        :rowKey="(record, index) => record.cameraId"
        :scroll="{
          y: scrollY,
          x:'100%'
        }"
        bordered
      >
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import TitleCom from '@/components/TitleCom';
import roleUserMixin from '@/components/mixins/relateRoleUser';
import { CODE_OK } from '@/request/config_code';
import { handleExportFile } from '@/tools';
import { mapState, mapActions } from 'vuex';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'DialogDetail',
  components: { TitleCom },
  mixins: [roleUserMixin,ResizableTable],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentRecords: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this.getCemeraListDetail();
          // this.searchCameraFrom(); //显示列表的接口名称
          // this.searchCameraFromTest();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.getCemeraListDetail();
          // this.searchCameraFrom();
          // this.searchCameraFromTest();
        },
      },
      /* data, */
      columns: [
        {
          title: '序号',
          dataIndex:'orderIndex',
          customRender: (text, record, index) => index + 1,
          width: 80,
          ellipsis: true,
        },
        {
          title: '省市',
          dataIndex: 'regionName',
          key: 'regionName',
          width:80,
          ellipsis: true,
        },
        {
          title: '管辖单位',
          dataIndex: 'organizationId',
          key: 'organizationId',
          width:100,
          ellipsis: true,
        },
        {
          title: '所属路线',
          dataIndex: 'roadCode',
          key: 'roadCode',
          width:100,
          ellipsis: true,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          key: 'cameraName',
          width:160,
          ellipsis: true,
        },
        {
          title: '桩号',
          dataIndex: 'kmPile',
          key: 'kmPile',
          width:80,
          ellipsis: true,
        },
        {
          title: '经纬度',
          dataIndex: 'longAndLati',
          key: 'longAndLati',
          ellipsis: true,
          width:100,
        },
        {
          title: '所属类别',
          dataIndex: 'classifyCodeDesc',
          key: 'classifyCodeDesc',
          ellipsis: true,
          width:100
        },
        {
          title: '方向',
          dataIndex: 'derectionCodeDesc',
          key: 'derectionCodeDesc',
          ellipsis: true,
          width:100
        },
        {
          title: '来源',
          dataIndex: 'transcodingName',
          key: 'transcodingName',
          ellipsis: true,
          width:100
        },
        {
          title: '接入方式',
          dataIndex: 'reportSource',
          key: 'reportSource',
          customRender: (text) => {
            return text == '1' ? '网关' : '平台';
          },
          width:100,
          ellipsis: true,
        },
        {
          title: '摄像机状态',
          dataIndex: 'cameraStatusDesc',
          key: 'cameraStatusDesc',
          ellipsis: true,
          width:100
        },
        {
          title: '摄像机类型',
          dataIndex: 'cameraType',
          key: 'cameraType',
          ellipsis: true,
          width:100
        },
      ],
      dataList: [],
      organizationId: [],
      roadId: undefined,
      cameraName: '',
      isScroll: false,
      scrollX: '1600px',
      scrollY: '400px',
      // scroll: { y: window.innerHeight - 385 },
    };
  },
  computed: {
    ...mapState({
      organizationList: (state) => state.collect.organizationList,
      roadList: (state) => state.collect.roadList,
    }),
  },
  watch: {
    visible(nv, ov) {
      if (nv !== ov) {
        this.getSelectedRole(this.currentRecords.groupId);
        this.getSelectedUser(this.currentRecords.groupId);
        this.getCemeraListDetail();
        this.$nextTick(() => {
          this.getScroll();
        });
      }
    },
  },
  created() {
    // this.getSelectedRole(this.currentRecords.groupId);
    // this.getSelectedUser(this.currentRecords.groupId);
    this.getOrganationList();
    this.getRoadList();
    // this.getCemeraListDetail();fixsxy
  },
  mounted() {},
  methods: {
    ...mapActions(['getOrganationList', 'getRoadList']),
    getScroll() {
      let tableConWidth = this.$refs.tableCon.$el.clientWidth;
      this.isScroll = tableConWidth > 1600 ? false : true;
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectRoad(e) {
      this.roadId = e;
    },
    selectOrganization(value) {
      this.organizationId = value;
    },
    handleCancel() {
      this.organizationId = [];
      this.roadId = undefined;
      this.cameraName = '';
      this.paginationOpt.current = 1;
      this.$emit('close');
    },
    getCemeraListDetail() {
      let paramsObj = {
        groupId: this.currentRecords.groupId,
        params: {
          organizationId:
            this.organizationId.length > 0
              ? this.organizationId[this.organizationId.length - 1]
              : '',
          roadCode: this.roadId,
          cameraName: this.cameraName,
          currPage: this.paginationOpt.current,
          pageSize: this.paginationOpt.defaultPageSize,
        },
      };
      this.$api.cameraGroup.getCemeraListDetail(paramsObj).then((res) => {
        if (res.code === CODE_OK) {
          this.dataList = res.data;
          this.paginationOpt.total = res.total;
        }
      });
    },
    searchHandle() {
      this.paginationOpt.current = 1;
      this.getCemeraListDetail();
    },
    resetHandle() {
      this.organizationId = [];
      this.roadId = undefined;
      this.cameraName = '';
      this.paginationOpt.current = 1;
      this.getCemeraListDetail();
    },
    exportHandle() {
      if (!this.hasPermission(1092111312)) {
        this.permissionWarning();
        return;
      }

      let newParams = {
        groupId: this.currentRecords.groupId,
        params: {
          organizationId:
            this.organizationId.length > 0
              ? this.organizationId[this.organizationId.length - 1]
              : '',
          roadCode: this.roadId,
          cameraName: this.cameraName,
        },
      };
      let objParams = {
        data: newParams,
        instructionsDel: {
          module: '摄像机组管理',
          page: '',
          feature: '数据导出',
          description: '数据导出: 摄像机列表',
        },
      };
      this.$api.cameraGroup.exportCameraList(objParams).then((res) => {
        handleExportFile(res, '摄像机详情.xls');
      });
    },
  },
};
</script>

<style scoped>
.sp-item {
  max-width: 60px;
  background: #838486;
  padding: 2px 4px;
  color: #fff;
  border-radius: 3px;
}
.scroll-ht {
  max-height: 60px;
}
</style>
