import { CODE_OK } from '@/request/config_code';
// 定义一个混入对象
const roleUserMixin = {
  data() {
    return {
      currentUser: [],
      currentRole: []
    };
  },
  methods: {
    getSelectedRole(groupId) {
      this.$api.cameraGroup.getSelectedRole({ groupId }).then(res => {
        if (res.code === CODE_OK) {
          this.currentRole = res.data;
        }
      });
    },
    getSelectedUser(groupId) {
      this.$api.cameraGroup.getSelectedUser({ groupId }).then(res => {
        if (res.code === CODE_OK) {
          this.currentUser = res.data;
        }
      });
    }
  }
};
export default roleUserMixin;
