<template>
  <a-modal
    title="关联角色"
    :visible="visible"
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    width="25%"
    @cancel="handleCancel"
  >
    <div>
      <span class="text-white block m-b-xs"
        ><span class="text-red">*</span>选择角色:</span
      >
      <a-select
        mode="multiple"
        class="full-width"
        placeholder="请选择角色"
        v-model="currentRole"
      >
        <a-select-option v-for="item in roleList" :key="item.roleCode">
          {{ item.roleName }}
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>

<script>
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'RoleModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    groupIds: {
      type: Array,
      default() {
        return [];
      },
    },
    groupList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      confirmLoading: false,
      roleList: [],
      currentRole: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this._getRoleList();
  },
  methods: {
    _getRoleList() {
      this.$api.cameraGroup.getRoleList().then((res) => {
        if (res.code === CODE_OK) {
          this.roleList = res.data;
        }
      });
    },
    handleOk() {
      let params = {
        groupIds: this.groupIds,
        relationIds: this.currentRole,
        status: 2,
      };
      let description = this.groupList.map((item) => item.groupName).join(',');

      let objParams = {
        data: params,
        instructionsDel: {
          module: '摄像机组管理',
          page: '',
          feature: '批量关联角色',
          description,
        },
      };
      this.confirmLoading = true;
      this.$api.cameraGroup.batchControl(objParams).then((res) => {
        if (res.code === CODE_OK) {
          this.confirmLoading = false;
          this.$message.success('批量关联角色成功');
          this.$emit('confirm');
          this.handleCancel();
        }
      });
    },
    handleCancel() {
      this.currentRole = undefined;
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
